////////////////////////////////
/* 
 webpack info
 components/vp_btn.js is bundeld 
 */

// RiveObserverLazy class code
import RiveObserverLazy from "./../00_utils/rive_observer.js";

// scrollbar
import RuOverlayScrollbar from "./../components/overlayscrollbars.js";

///////////////////////////////
// RIVE
// get rive nodes
const riveLazyCanvas = document.querySelectorAll(".lazy-load-rive");
// start rive observer
// const observeRives = new RiveObserverLazy(riveLazyCanvas);

const riveHomeFilter = document.querySelector(".rive-home-filter");
const observeRiveFilter = new RiveObserverLazy(riveHomeFilter);
const riveHomeMicro = document.querySelector(".rive-home-micro");
const observeRiveMicro = new RiveObserverLazy(riveHomeMicro);
const riveHomeInnovation = document.querySelector(".rive-home-innovation");
const observeRiveInnovation = new RiveObserverLazy(riveHomeInnovation);

///////////////////////////////
// overlay Scrollbars
document.addEventListener("DOMContentLoaded", function () {
  const vpScrollbar = new RuOverlayScrollbar("#scrollbars_vp");
});
