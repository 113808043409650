// import * as rive from "@rive-app/canvas"; // rive with text support
import * as rive from "@rive-app/canvas-lite"; // rive without text support - file size

export default class LazyLoadIntersectionObserver {
  constructor(element) {
    this.element = element; // selected elements
    this.fetchedData = null; // date from rest api
    this.elemData;
    this.animationLoaded = false;
    this.initialLoad = true;
    this.isSmall; // = entry.contentRect.width <= 520 ? true : false; // Initialize size check
    this.isCurrentSmall;
    this.rive;

    this.getData();
  }

  async getData() {
    // get the page slug
    const pathArray = window.location.pathname.split("/");
    let slug = pathArray[pathArray.length - 1]; // This assumes the slug is the last part of the URL
    console.log("slug", slug);
    // home has no slug in the url so it has to be set here
    // slug ? slug : "home"; // somehow is the ternary not working here
    if (!slug) {
      slug = "home";
    }

    // fetch start
    const fetchUrl = `${window.location.protocol}//${window.location.host}/wp-json/wp/v2/pages/?slug=${slug}&_fields=acf`;

    try {
      const response = await fetch(fetchUrl);
      const result = await response.json();
      this.fetchedData = result[0].acf; // get to the contents
      console.log("fetchedData", this.fetchedData); // Check the fetched data
      this.init(); // run init when data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  init() {
    console.log("observe rive in class");

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // const elemData = entry.target.elemData;
          console.log("in init");
          if (!this.animationLoaded) {
            console.log("runresize", this.elemData);
            this.runResize(entry);

            this.animationLoaded = true;
          }
          observer.unobserve(entry.target);
        }
      });
    };

    // set the margin to offset the trigger
    // this.element.forEach((element) => {
    const acfKey = this.element.dataset.acfName; // get the elements key for selecting data
    console.log("acfKey", acfKey);
    const fetchedDataArray = Object.keys(this.fetchedData).find(
      (key) => key === acfKey
    );
    console.log("fetchedDataArray", fetchedDataArray);

    if (fetchedDataArray) {
      this.elemData = this.fetchedData[acfKey]; // Store the content of acfKey in elemData
      console.log("elemData", this.elemData);

      // assign the max percentage or px value
      const rootMarginValue = this.elemData.unit
        ? `${this.elemData.rootmargin_px}px`
        : `${this.elemData.rootmargin_per}%`;
      console.log(rootMarginValue);

      // convert threshold value to number
      const thresholdValue = Number(this.elemData.threshold);
      console.log(thresholdValue);

      // this.element.elemData = elemData; // Store elemData specific to this element

      // set value
      this.observer = new IntersectionObserver(observerCallback, {
        rootMargin: rootMarginValue,
        threshold: thresholdValue,
      });

      this.observer.observe(this.element);
    } else {
      console.log("Desired key not found in fetchedData");
    }
    // });
  }

  runResize(entries) {
    const resizeObserver = new ResizeObserver((entries) => {
      // console.log("entries", entries);
      entries.forEach((entry) => {
        const currentWidth = entry.contentRect.width;
        this.isCurrentSmall = currentWidth <= 520 ? true : false;
        console.log(
          "isSmall outside",
          this.isSmall,
          this.isCurrentSmall,
          this.initialLoad
        );

        if (this.isSmall !== this.isCurrentSmall || this.initialLoad === true) {
          console.log(
            "inside is small ?",
            "isSmall: ",
            this.isSmall,
            "isCurrentSmall: ",
            this.isCurrentSmall,
            "initialLoad: ",
            this.initialLoad
          );
          // Check if width crosses the breakpoint
          if (currentWidth > 520) {
            this.cleanUpRive();
            console.log("resize large", entry, currentWidth);
            this.isSmall = false; // Update size check
            console.log("is big", this.isSmall, this.isCurrentSmall);
            this.runRiveFn(entry.target);
          }
          if (currentWidth <= 520) {
            this.cleanUpRive();
            console.log("resize small", entry, currentWidth);
            this.isSmall = true; // Update size check
            console.log("is small", this.isSmall, this.isCurrentSmall);
            this.runRiveFn(entry.target);
          }
        }
      });
    });
    resizeObserver.observe(entries.target);
  }

  runRiveFn(targetElement) {
    this.initialLoad = false; // set initialLoad false after first runRive
    let source;

    if (this.isSmall && this.elemData.mobile.src_mobile.url) {
      source = this.elemData.mobile.src_mobile.url;
      targetElement.setAttribute(
        "width",
        Number(this.elemData.mobile.width_mobile)
      );
      targetElement.setAttribute(
        "height",
        Number(this.elemData.mobile.height_mobile)
      );
    } else {
      source = this.elemData.desktop.src_desktop.url;
      targetElement.setAttribute(
        "width",
        Number(this.elemData.desktop.width_desktop)
      );
      targetElement.setAttribute(
        "height",
        Number(this.elemData.desktop.height_desktop)
      );
    }

    console.log(
      "source !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
      source
    );
    const changeLangVal = targetElement.dataset.isDe;

    this.rive = new rive.Rive({
      src: source, // host your Rive file and add the url to src
      canvas: targetElement,
      autoplay: this.elemData.autoplay,
      stateMachines: this.elemData.statemachine,
      layout: new rive.Layout({
        fit: this.elemData.layout_fit,
        alignment: this.elemData.alignment,
      }),
      onLoad: () => {
        this.rive.resizeDrawingSurfaceToCanvas(); // scharfe Darstellung

        const inputs = this.rive.stateMachineInputs(this.elemData.statemachine); // get inputs from statemachine
        const langBoolean = inputs.find((i) => i.name === "isDE"); // the input
        if (langBoolean && changeLangVal === "false") {
          langBoolean.value = false;
        }
      },
    });
  }

  cleanUpRive() {
    console.log("outside clean");
    if (this.initialLoad === false) {
      console.log("inside clean");
      this.rive.cleanup();
    }
  }

  // rm observer actively
  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
