const vp_btn_array = document.querySelectorAll(".vp_btn");
// const home = document.querySelector("main");
// const isHome = home.classList.contains("is_home");

// if (isHome) {
// add eventlistener to all vpBtns
vp_btn_array.forEach((elem) => {
  console.log("elem ", elem);
  elem.addEventListener("click", (e) => saveVp(elem, e));
});

//  get vp attribute and save to session storrage
const saveVp = (elem, e) => {
  // e.preventDefault();
  const vpAttr = elem.getAttribute("vp");
  console.log("vpAttr ", vpAttr);
  sessionStorage.setItem("vpbtncat", vpAttr);
  const storrage = sessionStorage.getItem("vpbtncat");
  console.log("storrage ", storrage);
};
// }
